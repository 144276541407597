var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.offerGroups.length === 1)?_c('v-btn',{staticClass:"edit-selection-button",attrs:{"color":"primary","disabled":_vm.disabled,"outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.selectOfferGroup(_vm.offerGroups[0])}}},[_c('mf-icon',{attrs:{"color":"primary","icon":"edit","size":19,"fill":""}}),_vm._v(" "+_vm._s(_vm.label)+" ")],1):_c('v-dialog',{attrs:{"max-width":"600px","scrollable":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"edit-selection-button",attrs:{"color":"primary","disabled":_vm.disabled,"outlined":"","loading":_vm.loading}},'v-btn',attrs,false),on),[_c('mf-icon',{attrs:{"color":"primary","icon":"edit","size":19,"fill":""}}),_vm._v(" "+_vm._s(_vm.label)+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Selecione um tipo de oferta")]),_c('v-card-text',[_c('span',[_vm._v(_vm._s(_vm.offerGroups.length)+" tipos de ofertas selecionados. Por favor, escolha um para editar:")]),_c('v-list',{staticClass:"my-2"},_vm._l((_vm.offerGroups),function(group,index){return _c('v-list-item',{key:("offer-group-" + index),staticClass:"offer-group-item",on:{"click":function($event){return _vm.selectOfferGroup(group)}}},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex flex-row align-center",staticStyle:{"gap":"16px"}},[_c('mf-icon',{attrs:{"medium":""}},[_vm._v("ad_group")]),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"4px"}},[_c('span',{staticClass:"group-name color-cinza"},[_vm._v(_vm._s(group.name))]),_c('span',{staticClass:"color-cinza"},[_vm._v(_vm._s(group.offerType))]),_c('span',{staticClass:"color-cinza"},[_c('span',{staticClass:"group-description"},[_vm._v("Quantidade: ")]),_vm._v(_vm._s(group.offers.length))])])],1)]),_c('v-list-item-action',[_c('mf-icon',[_vm._v("chevron_right")])],1)],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('mf-action-buttons',{attrs:{"cancel-button":{
            text: 'Fechar',
            action: _vm.handleClose,
            isVisible: true
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }