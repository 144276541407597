<template>
  <div>
    <v-btn
      v-if="offerGroups.length === 1"
      class="edit-selection-button"
      color="primary"
      :disabled="disabled"
      outlined
      :loading="loading"
      @click="selectOfferGroup(offerGroups[0])"
    >
      <mf-icon color="primary" icon="edit" :size="19" fill />
      {{ label }}
    </v-btn>

    <v-dialog v-else v-model="dialog" max-width="600px" scrollable :disabled="disabled">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="edit-selection-button" color="primary" :disabled="disabled" outlined v-bind="attrs" :loading="loading" v-on="on">
          <mf-icon color="primary" icon="edit" :size="19" fill />
          {{ label }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>Selecione um tipo de oferta</v-card-title>

        <v-card-text>
          <span>{{ offerGroups.length }} tipos de ofertas selecionados. Por favor, escolha um para editar:</span>
          <v-list class="my-2">
            <v-list-item v-for="(group, index) in offerGroups" :key="`offer-group-${index}`" class="offer-group-item" @click="selectOfferGroup(group)">
              <v-list-item-content>
                <div class="d-flex flex-row align-center" style="gap: 16px">
                  <mf-icon medium>ad_group</mf-icon>
                  <div class="d-flex flex-column" style="gap: 4px">
                    <span class="group-name color-cinza">{{ group.name }}</span>
                    <span class="color-cinza">{{ group.offerType }}</span>
                    <span class="color-cinza"><span class="group-description">Quantidade: </span>{{ group.offers.length }}</span>
                  </div>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <mf-icon>chevron_right</mf-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <mf-action-buttons
            :cancel-button="{
              text: 'Fechar',
              action: handleClose,
              isVisible: true
            }"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'SelectOffersToEdit',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    offerGroups: {
      type: Array,
      required: true
    }
  },
  emit: {
    selectOfferGroup: null
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    handleClose() {
      this.dialog = !this.dialog
    },
    selectOfferGroup(offerGroup) {
      this.$emit('selectOfferGroup', offerGroup)
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-selection-button {
  font-weight: 400 !important;
  text-transform: unset !important;
  letter-spacing: 0.02px !important;
  border-radius: 8px !important;
  padding: 0 16px 0 12px !important;
  font-size: 14px !important;
  line-height: 18px !important;
  height: 38px !important;

  ::v-deep .v-btn__content {
    gap: 8px;
  }

  ::v-deep .v-btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.offer-group-item {
  padding: 0 8px !important;
  border-bottom: 1px dotted #d2d6d9;

  .group-name {
    font-size: 16px;
    font-weight: 700;
  }

  .color-cinza {
    color: #4b525b;
  }

  .group-description {
    color: #7b7b7b;
  }
}
</style>
